import { AuthGuard } from './core/guard/auth.guard.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  }, 
  {
    path: 'reset-password/:token/:email',
    loadChildren: () => import('./auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: '',
    redirectTo: '/demo/registro',
    pathMatch: 'full',
  },
   {
    path: 'solicitud',
    redirectTo: '/demo/solicitud',
    pathMatch: 'full',
  },
  {
    path: 'historial',
    redirectTo: '/demo/historial',
    pathMatch: 'full',
  },
  {
    path: 'panel',
    redirectTo: '/demo/panel',
    pathMatch: 'full',
  },
  {
    path: 'dador',
    redirectTo: 'demo/registro/dador',
    pathMatch: 'full',
  },
  {
    path: 'empresa',
    redirectTo: 'demo/registro/empresa',
    pathMatch: 'full',
  },
  {
    path: 'transportista',
    redirectTo: 'demo/registro/transportista',
    pathMatch: 'full',
  },

  
  /*
  {
     path: 'home',
     loadChildren: () => import('../app/demo/home/home.module').then(m => m.HomeModule),
     canActivate: [AuthGuard],
   },
   */
  
  {
     path: 'home',
     loadChildren: () => import('../@vex/layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard],
   },
  
   /*
   {
     path: 'historial',
     loadChildren: () => import('../app/history/history.module').then(m => m.HistoryModule), canActivate: [AuthGuard],
   }, */
  {
    path: 'demo',
    loadChildren: () => import('../app/demo/demo.module').then(m => m.DemoModule),
  },
  {
    path: '**',
    redirectTo: '/demo',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
