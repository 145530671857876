import { AuthService } from 'src/app/core/service/auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    ) {}

  canActivate(): boolean {
    if (localStorage.getItem('user') || sessionStorage.getItem('user')) {
      this.authService.SaveDataUser(localStorage.getItem('user') || sessionStorage.getItem('user'));
      return true;
    }
    this.router.navigate(['login']);
    return false;
  }
}
