// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url_api: 'https://s4.quilsoft.com/shiftcargasback/public/api',
  url_pictures: 'http://192.168.88.35/shift-backend/',
  // url_api: 'http://192.168.0.12/shift-backend/api/v3/',
  // url_pictures: 'http://192.168.0.12/shift-backend/',

};

// localhost testing lucas jarpa
/*
	Desktop
	url_api: 'http://localhost/quilsoft/pruebas/cargas/public/api'

	Notebook
	url_api: 'http://localhost/quilsoft/shift-cargas/backend/shiftcargasback/public/api'

*/
// 


/* URL DARO */
//export const URL_API = 'http://192.168.88.35/shift-backend/api/v3/';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
