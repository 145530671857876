import { Component, OnInit } from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 

@Component({
  selector: 'app-modal-spinner-loader',
  templateUrl: './modal-spinner-loader.component.html',
  styleUrls: ['./modal-spinner-loader.component.scss']
})
export class ModalSpinnerLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	console.log('Running Loading');
  }

}
