import { Injectable } from '@angular/core';
import { RouteNavigation } from 'src/interfaces/routers-interface';
import { iconsFA } from 'src/static-data/icons-fa';
import { Router } from '@angular/router';




@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  car_alt = iconsFA['car-alt'];
  motorcycle = iconsFA.motorcycle;
  box = iconsFA.box;

  submenu: RouteNavigation[] = [
    {
      icon: this.car_alt,
      label: 'Alta Carga',
      route: '/solicitud'

    },

    {
      icon: this.car_alt,
      label: 'Historial',
      route: '/historial'

    },
    {
      icon: this.car_alt,
      label: 'Panel',
      route: '/panel'

    },

    {
      icon: this.car_alt,
      label: 'cerrar sesion',
      route: '/login'

    },
  ];





  rol;
  userLoggin;


  constructor(
  private router: Router) { }


  ngOnInit() {
       this.rol = localStorage.getItem('role');
       this.userLoggin = localStorage.getItem('user');
  

  }




  logout(){
        localStorage.clear();
        this.router.navigate(['/login']);

  }


}
