import { MaterialModule } from './material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorFadePipe } from './pipes/color/color-fade.pipe';
import { DateTokensPipe } from './pipes/date-tokens/date-tokens.pipe';
import { RouterModule } from '@angular/router';
import { IconModule } from '@visurel/iconify-angular';
import { RelativeDateTimePipe } from './pipes/relative-date-time/relative-date-time.pipe';
import { TableComponent } from './component/table/table.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InfoComponent } from './component/info-voucher/info.component';
import { NgxPermissionsModule } from 'ngx-permissions';



@NgModule({
  declarations: [ColorFadePipe, DateTokensPipe, RelativeDateTimePipe, TableComponent, InfoComponent],
  exports: [ColorFadePipe, RelativeDateTimePipe, DateTokensPipe, TableComponent],
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    NgxPermissionsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ]
})
export class SharedModule { }
