import { stagger40ms } from './../../../../@vex/animations/stagger.animation';
import { fadeInUp400ms } from './../../../../@vex/animations/fade-in-up.animation';
import { TableColumn } from './../../../../@vex/interfaces/table-column.interface';
import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { of, ReplaySubject, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { iconsFA } from 'src/static-data/icons-fa';
import icMail from '@iconify/icons-ic/twotone-mail';
import { filter } from 'rxjs/operators';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icMap from '@iconify/icons-ic/twotone-map';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icFolder from '@iconify/icons-ic/twotone-folder';
import { MatSelectChange } from '@angular/material/select';
import { SelectionModel } from '@angular/cdk/collections';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ]
})


export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public columns: any;
  @Input() public aioTableData: any;
  @Input() public labels: any;
  dataSource: MatTableDataSource<any> | null;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new FormControl();
  subject$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  data$: Observable<any[]> = this.subject$.asObservable();
  customers: any[];
  icPhone = icPhone;
  icMail = icMail;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  icMap = icMap;
  icEdit = icEdit;
  icSearch = icSearch;
  icDelete = icDelete;
  icAdd = icAdd;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;
  eye = iconsFA.eye;

  @Output()
  viewReservation = new EventEmitter<any>();
  @Output()
  viewVouchers = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    console.log('entra aqui');
    this.getData().subscribe(customers => {
      console.log('dime esto malditasea', customers);
      this.subject$.next(customers);
    });
    console.log('sigue');
    this.dataSource = new MatTableDataSource();
    this.data$.pipe(
      filter<any[]>(Boolean)
    ).subscribe(customers => {
      this.customers = customers;
      this.dataSource.data = customers;
    });
    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  ngAfterViewInit()  {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log('clofdsñalkfjkldsaj', this.dataSource.paginator, this.dataSource.sort);
  }
  ngOnDestroy(): void {}

  getData() {
    console.log('this table', this.aioTableData);
    return of(this.aioTableData.map(customer =>  (customer)));
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }
  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    }
    trackByProperty<T>(index: number, column: TableColumn<T>) {
      return column.property;
    }
    onLabelChange(change: MatSelectChange, row: any) {
      const index = this.customers.findIndex(c => c === row);
      this.customers[index].
      labels = change.value;
      this.subject$.next(this.customers);
    }
  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  viewResr(number: number) {
    console.log('number', number);
    this.viewReservation.emit({event: true, data: number});
  }

  viewVoucher(object: any) {
    console.log('data', object);
    this.viewVouchers.emit({event: true, data: object});
  }

}
