import { iconsFA } from './../../../../static-data/icons-fa';
import { Component, OnInit, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  viewReservation = new EventEmitter<boolean>();
  house: any = [
    { label: 'Fecha de creación', value: '12/11/2019 11:14:40'},
    { label: 'Fecha de creación', value: '12/11/2019 11:14:40'}
  ];

  map =  iconsFA.map;
  print = iconsFA.print;

  constructor(

  ) { }

  ngOnInit() {

  }


}
