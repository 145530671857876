import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, RolesAndPermission } from '../../../shared/interfaces/user.permission';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: User;
  UserPermission: RolesAndPermission;

  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<any> {
    const data = new HttpParams()
      .set('email', email).set('password', password);

    const path = environment.url_api + '/login';
    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    };
    
    return this.http.post(path, data.toString(), httpOptions);
  }


  register(email: string,
   password: string,
   passwordConfirm: string,
   cuit:string,
   phone:string,
   username:string,
   razonsocial:string,idrol:string): Observable<any> {
    const data = new HttpParams()
      .set('email', email).set('password', password).set('password_confirm', passwordConfirm).set('cuit',cuit).set('phone',phone).set('name',username).set('razonsocial',razonsocial).set('idrol',idrol);

    const path = environment.url_api + '/register';
    console.log('Dentro de la autenticacion');
    console.log('Parametros');
    console.log(data);    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    };
    
    return this.http.post(path, data.toString(), httpOptions);


  }





    
  resetPassword(token: string,
  email: string,
  password: string,
  password_confirmation: string): Observable<any>{
      
      const data = new HttpParams().set('token',token).set('email',email).
      set('password',password).
      set('password_confirmation',password_confirmation);

      const path = environment.url_api + '/password/reset';

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':'application/x-www-form-urlencoded'
        }),
      };

      return this.http.post(path,data.toString(),httpOptions);
  }

    



   SaveDataUser(user: any): void {
    this.user = user;
    this.UserPermission = user;
   }
}
