import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  token: string = null;
  constructor() { }

  setToken(token: string) {
    this.token = token;
  }

  getToken(): string {
    return this.token;
  }

  clearToken() {
    this.token = null;
  }

  get(url: string, body: any = {}) {

    const promise = new Promise<any>((resolve, reject) => {
      const header = {
        'Accept': 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.token
      };


    });
    return promise;
  }

  post(url: string, body: any = {}) {
    const promise = new Promise<any>((resolve, reject) => {
      const header = {
        'Accept': 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.token
      };


    });
    return promise;
  }

  delete(url: string, body: any = {}) {
    const promise = new Promise<any>((resolve, reject) => {
      const header = {
        'Accept': 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.token
      };


    });
    return promise;
  }


}
