import { environment } from './../environments/environment.prod';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ApiService } from './services/api/api.service';
import { AuthService } from './core/service/auth/auth.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

//import { PreTransportRequestComponent } from './pre-transport-request/pre-transport-request.component';
//import { HomeComponent } from './home/home.component';

import * as Sentry from '@sentry/browser';
import { ChooseUserTypeComponent } from './demo/choose-user-type/choose-user-type.component';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { ModalSpinnerLoaderComponent } from './components/modal-spinner-loader/modal-spinner-loader.component';
//import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

// autocomplete google places production testing




// if (environment.production === true) {
//   Sentry.init({
//     dsn: 'https://ef5bd239375a4e0a89a2785f59f0e1af@sentry.io/1839597'
//   });
// }

// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() {}
//   handleError(error) {
//     const eventId = Sentry.captureException(error.originalError || error);
//     // Sentry.showReportDialog({ eventId });
//   }
// }


@NgModule({
  declarations: [AppComponent, ModalSpinnerLoaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    // Vex
    VexModule,
    SharedModule,
    CoreModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  // useClass: SentryErrorHandler
  // , { provide: ErrorHandler}
  providers: [ApiService, AuthService, HttpClient],
  bootstrap: [AppComponent]
})
export class AppModule { }
